<template>
    <Modal
        class="wrap"
        v-model="modal"
        title=""
        footer-hide
        :mask-closable="false"
        width="1200"
        @on-visible-change="handleVisibleChange"
    >
        <article v-html="content" class="content-wrap markdown-body">
            <h1>Unicorns</h1>
            <p>All the things</p>
        </article>
    </Modal>  
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            content: {
                default: ''
            }
        },
        data () {
            return {
                modal: false
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
            }
        },
        methods: {
            handleVisibleChange(e) {
                if (!e) {
                    this.cancel()
                }
            },
            cancel() {
                this.$emit('cancel')
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
@deep: ~">>>";
.wrap {
    .content-wrap {
        position: relative;
        margin-top: 20px;
        min-height: 300px;
        @{deep} .hljs-right {
            text-align: right;
        }
        @{deep} .hljs-center {
            text-align: center;
        }
        @{deep} .hljs-left {
            text-align: left;
        }
        @{deep} .hljs {
            overflow: auto;
        }
        @{deep} strong {
            font-weight: bolder;
        }
    }
}
</style>